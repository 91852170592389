import React, { useCallback, useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, nameSource } from '../domain/info'
import { toolsList } from '../domain/tools'
import { breadcrumbList } from '../domain/breadcrumb'
import { headers } from '../domain/headers'
import { FilterContaier } from './styles'
import { Date as DatePicker } from '../../../../components/Form/date'
import { useLoading } from '../../../../hooks/loading'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { SearchComponentProduct } from '../components/SearchComponentProduct'
import { Select } from '../../../../components/Form'
const List: React.FC = () => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const [partners, setPartners] = useState<any[]>([])
  const [parameters, setParameters] = useState<any>()
  const [status, setStatus] = useState<any[]>([])
  const [statusValue, setStatusValue] = useState<string>('')
  const [startDate, setStartDate] = useState<Date>()
  const [endDate, setEndDate] = useState<Date>()
  const [partnersSearch, setPartnersSearch] = useState<any[]>([
    { id: 0, name: '' }
  ])
  const [selectedPartner, setSelectedPartner] = useState([
    { id: 0, selected: false }
  ])
  const [hasFocus, setHasFocus] = useState([{ id: 0, hasFocus: false }])

  const loadPartners = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/commercial/partners')
      setPartners(data)
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])
  const loadStatus = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/commercial/proposalsStatus')
      const uniqueIds: any[] = []

      const unique = data.filter((item: { id: number; status: string }) => {
        const isDuplicate = uniqueIds.includes(item.status)

        if (!isDuplicate) {
          uniqueIds.push(item.status)

          return true
        }

        return false
      })
      setStatus(
        unique.map(
          (item: { id: number; status: string }) =>
            item && { name: item.status, value: item.status }
        )
      )
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])
  useEffect(() => {
    loadPartners()
    loadStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container
      pageTitle="Listagem"
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <FilterContaier>
        <div className="row">
          <div className="col-md-3">
            <DatePicker
              name="start_date"
              label="Data de início"
              selected={startDate}
              onChange={e => {
                if (endDate) {
                  if (e > endDate) {
                    setEndDate(null)
                  }
                }
                setStartDate(e)
              }}
              controlled
            />
          </div>
          <div className="col-md-3">
            <DatePicker
              name="end_date"
              label="Data de término"
              selected={endDate}
              minDate={startDate}
              onChange={e => setEndDate(e)}
              controlled
            />
          </div>
          <div className="col-md-3">
            {SearchComponentProduct({
              index: 0,
              data: partnersSearch,
              setData: setPartnersSearch,
              searchData: partners,
              selected: selectedPartner,
              setSelected: setSelectedPartner,
              label: 'Parceiro',
              hasFocus,
              setHasFocus,
              name: 'partner_id'
            })}
          </div>
          <div className="col-md-3">
            <Select
              name="status"
              label="Status"
              id="status"
              value={statusValue}
              onChange={({ target }) => setStatusValue(target.value)}
              options={status}
              blank
            />
          </div>
        </div>
        <footer>
          <button
            className="btn btn-light-primary"
            onClick={() => {
              setStatusValue('')
              setStartDate(null)
              setEndDate(null)
              setParameters([])
              setSelectedPartner([{ id: 0, selected: false }])
              setPartnersSearch([{ id: 0, name: '' }])
            }}
          >
            LIMPAR
          </button>
          <button
            className="btn btn-primary"
            onClick={() => {
              setParameters([
                {
                  status: statusValue || undefined,
                  startDate,
                  endDate,
                  partner_id: selectedPartner[0]?.id || undefined
                }
              ])
            }}
          >
            BUSCAR
          </button>
        </footer>
        <hr className="divider" />
      </FilterContaier>
      <DataTable
        entity={nameEntity}
        source={nameSource}
        format={{ orderBy: '' }}
        notHasChildren
        headers={headers}
        searchParameters={parameters}
      />
    </Container>
  )
}
export default List
