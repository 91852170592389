/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, nameEntity } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import { Title } from 'components/Title'
import DataTable from 'components/DataTable'
import Tabs from 'components/Tabs'
import Tab from 'components/Tabs/Tab'

interface ProposalData {
  id?: number
  partner_id: number
  seller_name: string
  seller_cpf: string
  seller_rg: string
  seller_birth_date: Date
  seller_phone: string
  seller_cell_phone: string
  seller_email: string
  seller_type_account: string
  seller_bank_code: string
  seller_agency: string
  seller_account: string
  seller_account_digit: string
  customer_name: string
  customer_cpf: string
  customer_rg: string
  customer_birth_date: Date
  customer_phone: string
  customer_cell_phone: string
  customer_email: string
  customer_fgts_value: number
  customer_gross_value: number
  customer_entry_value: number
  customer_hiring_term: number
  customer_bank_id: number
  immobile_type: 'Home' | 'Apartment'
  immobile_situation: 'New' | 'Used'
  immobile_registry: string
  immobile_registration: string
  immobile_value: string
  immobile_zip_code: string
  immobile_state: string
  immobile_district: string
  immobile_street: string
  immobile_number: string
  immobile_city: string
  immobile_complement?: string
  created_at?: string
  updated_at?: string
  partner?: {
    name: string
  }
  createdUser?: { name: string }
}

const View = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const [proposal, setProposal] = useState<ProposalData | null>(null)
  const { addToast } = useToast()
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })
  const searchParametersAuditLog = useRef([
    { entity: nameEntity, entity_id: id }
  ])
  const searchParametersProposalStatus = useRef([{ proposal_id: id }])
  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    async function loadProposal(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<ProposalData>(apiList(id))
        const { data } = response
        setProposal(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a prospecção',
          description:
            'Houve um error ao carregar a prospecção, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadProposal()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-default">
            <Title title="Detalhes" className="mt-0" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cód.
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.id}</p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Parceiro" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Nome
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.partner?.name}</p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Vendedor" />
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Nome
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  CPF
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_cpf}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Identidade
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_rg}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Data de Nascimento
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_birth_date}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Telefone
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_phone}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Celular
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_cell_phone}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  E-mail
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_email}</p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Dados Bancários" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Tipo
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_type_account}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cód. Banco
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_bank_code}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  Agência
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.seller_agency}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Conta
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_account}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Conta DV
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.seller_account_digit}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Comprador" />
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Nome
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.customer_name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  CPF
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.customer_cpf}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Identidade
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.customer_rg}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Data de Nascimento
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_birth_date}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Telefone
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_phone}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Celular
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_cell_phone}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  E-mail
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_email}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Valor FGTS
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_fgts_value}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Renda Bruta
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_gross_value}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Valor Entrada
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_entry_value}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Prazo Contratação
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_hiring_term}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Banco
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.customer_bank_id}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Imóvel" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Tipo
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.immobile_type}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  Situação
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_situation}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cartório
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_registry}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Matricula
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_registration}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Valor
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_value}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  CEP
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_zip_code}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Logradouro
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_street}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Número
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_number}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Complemento
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_complement}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Bairro
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_district}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cidade
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.immobile_city}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Estado
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.immobile_state}
                </p>
              </div>
            </div>
          </div>
          <div className="row mb-default">
            <Title title="Informações Adicionais" />
            <div className="col-2">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Criador por
                </label>
                <p className=" fs-6 text-gray-800">
                  {proposal?.createdUser?.name}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cadastrado em
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Atualizado em
                </label>
                <p className=" fs-6 text-gray-800">{proposal?.updated_at}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="">
            <Tabs>
              <Tab key={0} title="Status">
                <div className="p-9">
                  <div className="card-header p-0">
                    <div className="card-title m-0 fw-bolder fs-6 col">
                      <p>Listagem</p>
                    </div>
                    {/* <div
                      className="d-flex align-items-center text-primary"
                      onClick={handleClickOnOpenModalQuery}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="fa fa-plus " />
                      <span className="ms-2">Consultar Banco</span>
                    </div> */}
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      // entityId={id}
                      format={{ orderBy: '' }}
                      source="proposalStatus"
                      entity="ProposalStatus"
                      headers={[
                        {
                          name: 'Nome',
                          field: 'statusProposal.name',
                          sortable: true
                        },
                        {
                          name: 'Criado por',
                          field: 'createdUser.name',
                          sortable: true
                        },
                        {
                          name: 'Criado em',
                          field: 'created_at',
                          sortable: true
                        }
                      ]}
                      searchParameters={searchParametersProposalStatus.current}
                    />
                  </div>
                </div>
              </Tab>
              <Tab key={1} title="Histórico">
                <div className="p-9">
                  <div className="card-header p-0">
                    <div className="card-title m-0 fw-bolder fs-6 col">
                      <p>Listagem</p>
                    </div>
                    {/* <div
                      className="d-flex align-items-center text-primary"
                      onClick={handleClickOnOpenModalQuery}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="fa fa-plus " />
                      <span className="ms-2">Consultar Banco</span>
                    </div> */}
                  </div>
                  <div className="">
                    <DataTable
                      entityId={id}
                      format={{ orderBy: '' }}
                      source="auditLogs"
                      entity="AuditLog"
                      searchParameters={searchParametersAuditLog.current}
                    />
                  </div>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${proposal?.id} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(proposal?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default View
