import styled from 'styled-components'
export const SearchContainer = styled.div<{ erro?: boolean }>`
  position: relative;
  width: 100%;

  ul {
    width: 100%;
    max-height: 230px;
    margin: 0;
    padding: 0;

    position: absolute;

    border: ${props => props.erro && '1px solid red'};
    color: ${props => (props.erro ? 'red' : '#000')};
    background: #fff;

    overflow: auto;
    z-index: 9992;

    li {
      font-size: 14px;
      padding: 3px;
      cursor: pointer;

      &:hover {
        background: #f1f2f3;
      }
    }
  }
  /* .container-input {
    display: flex;
    align-items: center;
  } */
  /* span.icon {
    font-size: 11px;
    margin-left: -24px;
    margin-top: 24px;
    position: relative;
  } */
`
