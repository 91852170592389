import React, { useEffect, useState } from 'react'
import { Content } from '../../../assets/layout/components/Content'

import { AsideDefault } from '../../../assets/layout/components/aside/AsideDefault'

import { HeaderWrapper } from '../../../assets/layout/components/header/HeaderWrapper'
import { useLocation } from 'react-router-dom'
import { MenuComponent } from '../../../assets/ts/components'
import {
  ActivityDrawer,
  CreateAppModal,
  DrawerMessenger,
  InviteUsers,
  UpgradePlan
} from '../../../assets/partials'
import { RightToolbar } from '../../../assets/partials/layout/RightToolbar'
import { ScrollTop } from '../../../assets/layout/components/ScrollTop'
import { Toolbar2 } from '../../../assets/layout/components/toolbar/Toolbar2'
import { Toolbar } from '../../../components/Toolbar'

const DefaultLayout: React.FC = ({ children }) => {
  const [toggleNewApp, setTogglenewApp] = useState(false)
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  return (
    <div className="d-flex flex-column flex-root">
      {/* begin::Page */}
      <div className="page d-flex flex-row flex-column-fluid">
        <AsideDefault />
        {/* begin::Wrapper */}
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          <HeaderWrapper />

          {/* begin::Content */}
          <div
            id="kt_content"
            className="content d-flex flex-column flex-column-fluid"
          >
            <Toolbar />
            {/* <Toolbar2 /> */}
            <Content>{children}</Content>
          </div>
          {/* end::Content */}
          {/* <Footer /> */}
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Page */}
      {/* begin:: Drawers */}
      <ActivityDrawer />
      <DrawerMessenger />
      {/* end:: Drawers */}

      {/* begin:: Modals */}
      <InviteUsers />
      {/* end:: Modals */}
      <ScrollTop />
    </div>
  )
}

export default DefaultLayout
