/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewList,
  toolsViewUpdate
} from '../domain/tools'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import Modal from '../../../../components/Modal'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { cnpjMask, cpfMask } from '../../../../utlis/mask'
import moment from 'moment'
import convertStringOrNumberToStringBoolean from 'utlis/convertStringOrNumberToStringBoolean'
import { Title } from 'components/Title'
import { TYPE_PERSON } from 'common/constants'

interface PartnerData {
  id: number
  name: string
  type: string
  document: string
  zip_code: string
  state: string
  district: string
  street: string
  city: string
  complement?: string
  observations?: string
  active: number
  create_user: number
  name_ownership: string
  cpf_cnpj_ownership: string
  type_account: string
  bank_code: string
  agency: string
  account: string
  account_digit: string
  email: string
  secondary_email: string
  phone: string
  cell_phone: string
  own_team: number
  indicator: number
  receive_help_cost: number
  created_by?: number
  different_ownership?: number
  number?: string
  created_at?: string
  updated_at?: string
  issue_invoice?: number
  agreementGroup: {
    name: string
  }
  user: {
    id: number
    name: string
  }
  createdUser?: { name: string }
}

const View = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [partner, setPartner] = useState<PartnerData | null>(null)
  const { addToast } = useToast()
  const searchParams = useRef([{ partner_id: `${id}` }])
  const [modalView, setModalView] = useState(false)
  const [currentItemView, setCurrentItemView] = useState<any>({})

  const dateOutFormat = 'DD/MM/YYYY'
  const dateInputFormat = 'DD/MM/YYYY'
  const [alert, setIsActiveAlert] = useState<{
    isActive: boolean
    id: number
    name: string
  }>({
    id: 0,
    isActive: false,
    name: ''
  })

  const { activeLoading, disableLoading } = useLoading()

  useEffect(() => {
    async function loadProspect(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get<PartnerData>(apiList(id))
        const { data } = response
        setPartner(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar a prospecção',
          description:
            'Houve um error ao carregar a prospecção, tente novamente mais tarde!'
        })
        if (path.includes('view')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadProspect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeLoading, addToast, history, location.pathname])

  const handlerClickButtonCancellAlert = () => {
    setIsActiveAlert({
      id: 0,
      isActive: false,
      name: ''
    })
    addToast({
      type: 'info',
      title: 'Operação cancelada.'
    })
  }

  const handlerClickButtonConfirmAlert = async (id: string) => {
    try {
      await api.delete(apiDelete(id))
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
    } catch (err) {
      setIsActiveAlert({
        id: 0,
        isActive: false,
        name: ''
      })
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Usuário removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Usuário não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleClickOnClose = () => {
    setModalView(false)
  }
  const handleCnpjCpfMask = (value: string) => {
    if (value.length === 14) {
      return cnpjMask(value)
    }
    return cpfMask(value)
  }

  return (
    <>
      <Container
        pageTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row mb-default">
            <Title title="Dados Pessoais" className="mt-0" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cód.
                </label>
                <p className=" fs-6 text-gray-800">{partner?.id}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Nome
                </label>
                <p className=" fs-6 text-gray-800">{partner?.name}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6 form-label">
                  Tipo
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.type?.toUpperCase() === 'PF'
                    ? 'Pessoa Física'
                    : 'Pessoa Jurídica'}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  {partner?.type?.toUpperCase() ===
                  TYPE_PERSON.PF.ABBREVIATION.toUpperCase()
                    ? TYPE_PERSON.PF.DOCUMENT.toUpperCase()
                    : TYPE_PERSON.PJ.DOCUMENT.toUpperCase()}
                </label>
                <p className=" fs-6 text-gray-800">{partner?.document}</p>
              </div>
            </div>
          </div>

          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Endereço" />
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  CEP
                </label>
                <p className=" fs-6 text-gray-800">{partner?.zip_code}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Logradouro
                </label>
                <p className=" fs-6 text-gray-800">{partner?.street}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Número
                </label>
                <p className=" fs-6 text-gray-800">{partner?.number}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Complemento
                </label>
                <p className=" fs-6 text-gray-800">{partner?.complement}</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Bairro
                </label>
                <p className=" fs-6 text-gray-800">{partner?.district}</p>
              </div>
            </div>
            <div className="col-4">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cidade
                </label>
                <p className=" fs-6 text-gray-800">{partner?.city}</p>
              </div>
            </div>

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Estado
                </label>
                <p className=" fs-6 text-gray-800">{partner?.state}</p>
              </div>
            </div>
          </div>

          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Contatos" />

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  E-mail
                </label>
                <p className=" fs-6 text-gray-800">{partner?.email}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  E-mail Secundário
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.secondary_email}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Telefone
                </label>
                <p className=" fs-6 text-gray-800">{partner?.phone}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Celular
                </label>
                <p className=" fs-6 text-gray-800">{partner?.cell_phone}</p>
              </div>
            </div>
          </div>

          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Dados Bancários" />

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Titularidade diferente
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(
                    partner?.different_ownership
                  )}
                </p>
              </div>
            </div>
            {!!partner?.different_ownership && (
              <>
                <div className="col-2">
                  <div className="form-group">
                    <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                      Nome do Titular
                    </label>
                    <p className=" fs-6 text-gray-800">
                      {partner?.name_ownership}
                    </p>
                  </div>
                </div>
                <div className="col-2">
                  <div className="form-group">
                    <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                      CPF/CNPJ do Titular
                    </label>
                    <p className=" fs-6 text-gray-800">
                      {handleCnpjCpfMask(partner?.cpf_cnpj_ownership)}
                    </p>
                  </div>
                </div>
              </>
            )}
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Tipo
                </label>
                <p className=" fs-6 text-gray-800">{partner?.type_account}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cód. Banco
                </label>
                <p className=" fs-6 text-gray-800">{partner?.bank_code}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Agência
                </label>
                <p className=" fs-6 text-gray-800">{partner?.agency}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Conta
                </label>
                <p className=" fs-6 text-gray-800">{partner?.account}</p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Conta DV
                </label>
                <p className=" fs-6 text-gray-800">{partner?.account_digit}</p>
              </div>
            </div>
          </div>
          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Sistema" />

            <div className="col-1">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Ativo
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.active ? 'Sim' : 'Não'}
                </p>
              </div>
            </div>

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Emite NF
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(partner?.issue_invoice)}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Equipe Própria
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(partner?.own_team)}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Recebe Ajuda de Custo
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(
                    partner?.receive_help_cost
                  )}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Recebe Ajuda de Custo
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(
                    partner?.receive_help_cost
                  )}
                </p>
              </div>
            </div>
            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Indicador
                </label>
                <p className=" fs-6 text-gray-800">
                  {convertStringOrNumberToStringBoolean(partner?.indicator)}
                </p>
              </div>
            </div>
          </div>

          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Observações" />

            <div className="col-12">
              <div className="form-group">
                <p className=" fs-6 text-gray-800">{partner?.observations}</p>
              </div>
            </div>
          </div>

          {/* <div className="separator my-5" /> */}

          <div className="row mb-default">
            <Title title="Informações Adicionais" />
            <div className="col-2">
              <div className="form-group">
                <label
                  htmlFor="name"
                  className="col-form-label fw-bold fs-6form-label fw-bold fs-6"
                >
                  Criador por
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.createdUser?.name}
                </p>
              </div>
            </div>
            <div className="col-1">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Existe Usuário
                </label>
                <p className=" fs-6 text-gray-800">
                  {partner?.user ? (
                    <Link to={`/users/view/${partner?.user?.id}`}>Usuário</Link>
                  ) : (
                    'Não'
                  )}
                </p>
              </div>
            </div>

            <div className="col-2">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Cadastrado em
                </label>
                <p className=" fs-6 text-gray-800">{partner?.created_at}</p>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="col-form-label fw-bold fs-6form-label fw-bold fs-6">
                  Atualizado em
                </label>
                <p className=" fs-6 text-gray-800">{partner?.updated_at}</p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Alert
        message={`Tem certeza que deseja excluir o registro ${alert.name} ?`}
        onClickCancellButton={handlerClickButtonCancellAlert}
        onClickConfirmButton={() => {
          handlerClickButtonConfirmAlert(String(alert.id))
        }}
        isActive={alert.isActive}
      />
      <Alert
        message={`Tem certeza que deseja excluir o registro ${partner?.name} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(partner?.id))
        }
        isActive={alertRemoveParent}
      />
      {/* {modalView && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalView}
          pageTitle={'Visualizar'}
          styles={{ padding: '30px 5px', overflow: 'auto' }}
          Children={
            <CustomModal
              type={currentItemView.api_name}
              isOpenInModal={{
                handleOnClose: handleClickOnClose,
                idParent: Number(currentItemView?.id)
              }}
              initialValues={currentItemView.result}
            />
          }
        />
      )} */}
    </>
  )
}

export default View
