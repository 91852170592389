import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { AsideTabs } from './AsideTabs'
import { AsideFooter } from './AsideFooter'
import { TabsBase } from './Tabs/_TabsBase'
import { LOCAL_STORAGE_KEYS } from 'common/constants'
import { ToggleComponent } from 'assets/ts/components'

const AsideDefault = () => {
  const toggleButton = useRef<HTMLButtonElement>()
  const { config } = useLayout()
  const { classes } = useLayout()
  const [link, setLink] = useState<any>(() => {
    const selectedMenu = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_MENU)

    if (selectedMenu) {
      return JSON.parse(selectedMenu)
    }
    return {} as any
  })

  const handleSetLink = useCallback((item: any) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_MENU, JSON.stringify(item))
    setLink(item)
  }, [])

  useEffect(() => {
    ToggleComponent.reinitialization()
  }, [])
  return (
    <div
      id="kt_aside"
      className={clsx('aside aside-extended', classes.aside.join(' '))}
      data-theme="dark"
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: false, lg: false}"
      data-kt-drawer-overlay="false"
      data-kt-drawer-width="auto"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_toggle"
    >
      {/* begin::Primary */}
      <div className="aside-primary d-flex flex-column align-items-lg-center flex-row-auto">
        {/* begin::Logo */}
        <div
          className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10"
          id="kt_aside_logo"
        >
          <a href="/dashboard" className="ps-2">
            <img
              src={toAbsoluteUrl('/media/logos/Vertical@White.png')}
              alt="logo"
              style={{ width: '90%' }}
            />
          </a>
        </div>
        {/* end::Logo */}
        {/* begin::Nav */}
        <div
          className="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0"
          id="kt_aside_nav"
        >
          <AsideTabs link={link} setLink={handleSetLink} />
        </div>
        {/* end::Nav */}
        <AsideFooter />
      </div>
      {/* end::Primary */}
      {config.aside.secondaryDisplay && (
        <>
          {/* begin::Secondary */}
          <div className="aside-secondary d-flex flex-row-fluid">
            {/* begin::Workspace */}
            <div className="aside-workspace my-5" id="kt_aside_wordspace">
              <TabsBase subMenus={link} />
            </div>
            {/* end::Workspace */}
          </div>
          {/* end::Secondary */}
          {/* begin::Aside Toggle */}
          <button
            id="kt_aside_toggle"
            className={clsx(
              'btn btn-sm btn-icon bg-body btn-color-gray-700 btn-active-primary position-absolute translate-middle start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex',
              classes.asideToggle.join(' ')
            )}
            ref={toggleButton}
            data-kt-toggle="true"
            data-kt-toggle-state="active"
            data-kt-toggle-target="body"
            data-kt-toggle-name="aside-minimize"
            style={{ marginBottom: '1.35rem' }}
          >
            <KTSVG
              // path="../../../../assets/media/icons/duotune/arrows/arr063.svg"
              path={toAbsoluteUrl('/media/icons/duotune/arrows/arr063.svg')}
              className="svg-icon-2 rotate-180"
            />
          </button>
          {/* end::Aside Toggle */}
        </>
      )}
    </div>
  )
}

export { AsideDefault }
