import React from 'react'
import { Input } from '../../../../../../components/Form'
import { SearchContainer } from './styles'

type SearchComponentProductParams = {
  index: number
  data: any[]
  setData: React.Dispatch<React.SetStateAction<any[]>>
  searchData: any[]
  selected: any[]
  setSelected: React.Dispatch<React.SetStateAction<any[]>>
  disabled?: boolean
  label?: string
  hasFocus?: any[]
  setHasFocus?: React.Dispatch<React.SetStateAction<any[]>>
}

export function SearchComponentProduct({
  index,
  searchData,
  data,
  setData,
  selected,
  setSelected,
  disabled,
  label,
  hasFocus,
  setHasFocus
}: SearchComponentProductParams) {
  const handleBlur = () => {
    setTimeout(() => {
      !data[index].id &&
        setData(prev => {
          const copy = [...prev]
          copy[index].name = ''
          return copy
        })
    }, 500)
  }

  return (
    <SearchContainer onBlur={handleBlur}>
      <div style={{ width: '100%' }}>
        <div style={{ display: 'flex', width: '100%' }}>
          <Input
            labelError={label || 'Permissão'}
            className="form-control"
            name={''}
            id="option"
            onChange={({ target }) => {
              setData(prev => {
                const copy = [...prev]
                copy[index].name = target.value
                copy[index].id = 0
                return copy
              })
              setSelected(prev => {
                const copy = [...prev]
                copy[index].selected = false
                copy[index].id = 0
                return copy
              })
            }}
            rules={{ required: true }}
            value={data[index].name}
            disabled={disabled}
            onClick={event => {
              event.stopPropagation()
              if (hasFocus[index].hasFocus) {
                event.currentTarget.blur()
                setHasFocus(prev => {
                  const copy = [...prev]
                  copy[index].hasFocus = false
                  copy[index].id = 0
                  return copy
                })
              } else {
                setHasFocus(prev => {
                  const copy = [...prev]
                  copy[index].hasFocus = true
                  copy[index].id = 0
                  return copy
                })
              }
            }}
            fullControlled
          />
          <span className="icon fa fa-chevron-down" />
        </div>
        <Input
          name={`data.${index}.id`}
          value={data[index].id}
          type="hidden"
          controlled
        />
        <ul>
          {hasFocus[index].hasFocus &&
            !selected[index].selected &&
            searchData
              .filter(f => f.name?.toLowerCase().includes(data[index].name))
              .map(item => (
                <li
                  key={item.id}
                  onClick={() => {
                    setData(prev => {
                      const copy = [...prev]
                      copy[index].id = item.id
                      copy[index].name = item.name
                      return copy
                    })
                    setSelected(prev => {
                      const copy = [...prev]
                      copy[index].selected = true
                      copy[index].id = item.id
                      return copy
                    })
                    setHasFocus(prev => {
                      const copy = [...prev]
                      copy[index].hasFocus = false
                      copy[index].id = item.id
                      return copy
                    })
                  }}
                >
                  {item.name}
                </li>
              ))}
        </ul>
      </div>
    </SearchContainer>
  )
}
