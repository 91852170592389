import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;

  }
  body{
    /* background-color: #eff3f8; */
    /* overflow-x: hidden; */

  }
  button {
    cursor: pointer;
  }
  hr, p {
    margin: 0;
  }
  .mb-default {
    margin-bottom: 0.5rem !important;
  }
`
