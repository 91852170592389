import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Container from '../../../../../components/Container'
import { useLoading } from '../../../../../hooks/loading'
import api from '../../../../../services/api'
import { FormRoles } from '../components/Form'
import { apiList } from '../domain/api'
import { nameActions } from '../domain/info'
import { breadcrumbUpdate } from '../domain/breadcrumb'
import { toolsUpdate } from '../domain/tools'
import { useToast } from '../../../../../hooks/toast'

const RolesUpdate = (): JSX.Element => {
  const { addToast } = useToast()
  const location = useLocation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const { activeLoading, disableLoading } = useLoading()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [roleItem, setRoleItem] = useState<{
    id: string
    name: string
    description: string
  }>({ id: '', name: '', description: '' })

  useEffect(() => {
    async function loadData() {
      activeLoading()
      try {
        const response = await api.get(apiList(id))
        const { data } = response
        setRoleItem(data)
        disableLoading()
      } catch (err) {
        const [, baseUrl, path] = location.pathname.split('/')
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o Grupo de usuário',
          description:
            'Houve um error ao carregar o grupo, tente novamente mais tarde!'
        })
        if (path.includes('update')) {
          history.push(`/${baseUrl}`)
        } else {
          history.push(`/${baseUrl}/${path}`)
        }
      }
    }
    loadData()
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])
  return (
    <Container
      pageTitle={nameActions.update.name}
      breadcrumb={breadcrumbUpdate}
      tools={toolsUpdate}
    >
      <FormRoles
        typeForm="update"
        initialValues={{
          idUpdate: Number(roleItem?.id),
          ...roleItem
        }}
      />
    </Container>
  )
}

export default RolesUpdate
