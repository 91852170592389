import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})
api.interceptors.request.use(config => {
  if (!config.url.includes('checkPermission')) {
    const pathVerify = [
      { name: 'create', method: 'POST' },
      { name: 'view', method: 'GET' },
      { name: 'update', method: 'PUT' },
      { name: 'delete', method: 'DELETE' }
    ]
    const url = window.location.pathname
    const path = pathVerify.find(pathItem =>
      url.split('/').find(item => pathItem.name === item)
    )
    config.headers.frontorigin = JSON.stringify({
      url,
      method: path?.method || 'GET'
    })
  }
  return config
})
export default api
