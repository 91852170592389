import React, { HTMLAttributes } from 'react'
import { Container } from './styles'

interface TitleProps extends HTMLAttributes<HTMLDivElement> {
  title: string
}
export const Title: React.FC<TitleProps> = ({ title, className }, ...rest) => {
  return (
    <Container className={'mt-5 ' + className} {...rest}>
      <h3>{title}</h3>
      <div className="separator my-5" />
    </Container>
  )
}
