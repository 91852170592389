import React, { useEffect, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../components/DataTable'
import { namePageTitle, nameSource } from '../domain/info'
import { breadcrumbList } from '../domain/breadcrumb'
import { apiList } from '../domain/api'
import api from '../../../../services/api'
import { useLoading } from '../../../../hooks/loading'
import { useToast } from '../../../../hooks/toast'

const List: React.FC = () => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const [keyCache, setKeyCache] = useState([''])
  useEffect(() => {
    async function loadData(): Promise<void> {
      activeLoading()
      try {
        const response = await api.get(apiList())
        const { data } = response
        setKeyCache(data)
        disableLoading()
      } catch (err) {
        disableLoading()
        addToast({
          type: 'error',
          title: 'Error ao carregar o concorrente',
          description:
            'Houve um error ao carregar o concorrente, tente novamente mais tarde!'
        })
      }
    }
    loadData()
  }, [addToast, activeLoading, disableLoading])

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Listagem'}
      breadcrumb={breadcrumbList}
      tools={[]}
    >
      {keyCache.length > 1 && (
        <DataTable
          products={keyCache}
          source={nameSource}
          format={{ orderBy: 'name' }}
          notHasChildren
          onActions={{
            onClickButtonRemove: (key: string) =>
              api.delete(`cache/delete/${key}`)
          }}
          headers={[
            { name: 'Nomes', field: 'name', sortable: true },
            { name: 'Ações', field: 'actions', sortable: false }
          ]}
        />
      )}
    </Container>
  )
}
export default List
