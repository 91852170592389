import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/StatusProposals/List'
import Create from '../../pages/Commercial/StatusProposals/Create'
import View from '../../pages/Commercial/StatusProposals/View'
import Update from '../../pages/Commercial/StatusProposals/Update'
import { CustomSwitch } from '../../components/CustomSwitch'

export const StatusProposalsRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/statusProposals" exact component={List} />
    <PrivateRoutes
      path="/commercial/statusProposals/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/commercial/statusProposals/view/:id"
      exact
      component={View}
    />
    <PrivateRoutes
      path="/commercial/statusProposals/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
