import styled from 'styled-components'

export const LinkContainer = styled.div`
  display: flex !important;
  color: #666;
  font-size: 14px;
  a {
    display: flex !important;
    align-items: center;
    margin: 0 3px;
    p {
      margin: 0 2px;
    }
  }
`
export const ContainerWrapper = styled.div`
  /* @media (min-width: 991px) {
    margin: 0 -40px;
  } */
`
