import React, { useEffect } from 'react'
import { PageLink, PageTitle } from '../../assets/layout/core'
const Dashboard: React.FC = () => {
  const dashboardBreadCrumbs: PageLink[] = [
    {
      title: 'Início',
      path: '/Tela Inícial',
      isSeparator: false,
      isActive: false
    }
  ]
  useEffect(() => {
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])
  return (
    <>
      <PageTitle breadcrumbs={dashboardBreadCrumbs}>Tela Inícial</PageTitle>
    </>
  )
}

export default Dashboard
