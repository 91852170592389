import React, { useEffect, useState, useCallback } from 'react'
import api from '../../../../../services/api'
import Form, { Input, Select } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'
import { useHistory } from 'react-router-dom'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'
import { FormContainer } from './styles'
import { SearchComponentProduct } from '../SearchComponentProduct'
import { Title } from 'components/Title'
import {
  dateInputFormat,
  INPUT_MASK,
  OPTIONS_IMMOBILE_SITUATION,
  OPTIONS_IMMOBILE_TYPES,
  OPTIONS_TYPE_ACCOUNT_BANK,
  states
} from 'common/constants'
import { IMaskInput } from 'react-imask'
import moment from 'moment'

type IsOpenInModalProps = {
  idParent: number
  handleOnClose: () => void
}

type ProposalData = {
  id?: number
  customer_name?: string
  customer_cpf?: string
  seller_name?: string
  seller_cpf?: string
  created_at?: string
  updated_at?: string
  partner_id?: number
  partner?: {
    name: string
  }
}

type TypesFormProps = {
  isOpenInModal?: false | IsOpenInModalProps
  initialValues?: ProposalData & {
    idUpdate: number
  }
  typeForm: 'create' | 'update'
}
export const FormProposal = ({
  isOpenInModal,
  initialValues,
  typeForm
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<ProposalData>()
  const [partners, setPartners] = useState<any[]>([])
  const [banks, setBanks] = useState<any[]>([])
  const [sellerBirthDate, setSellerBirthDate] = useState<Date>()
  const [customerBirthDate, setCustomerBirthDate] = useState<Date>()
  const [partnersSearch, setPartnersSearch] = useState<any[]>([
    { id: 0, name: '' }
  ])
  const [selectedPartner, setSelectedPartner] = useState([
    { id: 0, selected: false }
  ])
  const [hasFocus, setHasFocus] = useState([{ id: 0, hasFocus: false }])

  const loadBanks = useCallback(async () => {
    activeLoading()
    try {
      const banksResponse = await api.get('/commercial/banks')
      setBanks(
        banksResponse.data.map(
          (bank: { id: number; name: string }) =>
            bank && { name: bank.name, value: bank.id }
        )
      )
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])

  const loadPartners = useCallback(async () => {
    activeLoading()
    try {
      const { data } = await api.get('/commercial/partners')
      setPartners(data)
      disableLoading()
    } catch (err) {
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar o parceiro',
        description:
          'Houve um error ao carregar o parceiro, tente novamente mais tarde!'
      })
    }
  }, [activeLoading, addToast, disableLoading])

  useEffect(() => {
    loadPartners()
  }, [loadPartners])

  useEffect(() => {
    loadBanks()
  }, [loadBanks])

  useEffect(() => {
    if (initialValues) {
      setDefaultValues({
        ...initialValues
      })
      setPartnersSearch([
        {
          id: initialValues.partner_id,
          name: initialValues.partner?.name
        }
      ])
    }
  }, [initialValues])

  const onSubmitForm = async (data: any) => {
    const id = initialValues?.idUpdate
    const formData = {
      ...data,
      partner_id: Number(data?.data[0]?.id)
    }
    delete formData.data

    try {
      if (typeForm === 'create') {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          activeLoading()
          try {
            await api.post(apiCreate(), formData)
            handleOnClose()
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            handleOnClose()
            disableLoading()
            updateDataTable()
          }
        } else {
          try {
            activeLoading()
            await api.post(apiCreate(), formData)
            disableLoading()
            updateDataTable()
            addToast({
              type: 'success',
              title: 'Registro criado',
              description: 'Registro criado com sucesso'
            })
            history.push(nameActions.read.to)
          } catch (error) {
            addToast({
              type: 'error',
              title: 'Erro ao adicionar o registro',
              description:
                'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
            })
            disableLoading()
            updateDataTable()
          }
        }
      } else {
        if (isOpenInModal) {
          const { handleOnClose } = isOpenInModal
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            handleOnClose()
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            disableLoading()
            handleOnClose()
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        } else {
          try {
            activeLoading()
            await api.put(apiUpdate(String(id)), formData)
            updateDataTable()
            disableLoading()
            history.push(nameActions.read.to)
            addToast({
              type: 'success',
              title: 'Registro atualizado',
              description: 'Registro alterado com sucesso'
            })
          } catch (error) {
            history.push(nameActions.read.to)
            addToast({
              type: 'error',
              title: 'Erro ao atualizar o registro',
              description:
                'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
            })
          }
        }
      }
      disableLoading()
    } catch (err) {
      if (typeForm === 'create') {
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        if (isOpenInModal) isOpenInModal.handleOnClose()
      }
    }
  }

  return (
    <Form onSubmit={onSubmitForm} defaultValues={defaultValues}>
      <>
        <div className="row mb-default">
          <div className="col-6">
            {SearchComponentProduct({
              index: 0,
              data: partnersSearch,
              setData: setPartnersSearch,
              searchData: partners,
              selected: selectedPartner,
              setSelected: setSelectedPartner,
              label: 'Parceiro',
              hasFocus,
              setHasFocus,
              name: 'partner_id'
            })}
          </div>
        </div>
        <div className="row mb-default">
          <Title title="Vendedor" className="mt-0" />

          <Input
            className="col-4"
            name="seller_name"
            label="Nome"
            rules={{ required: true }}
          />

          <Input
            className="col-2"
            name="seller_cpf"
            label="CPF"
            rules={{ required: true }}
            mask={{
              mask: '999.999.999-99',
              type: 'cpf'
            }}
            controlled
          />
          <Input
            className="col-2"
            name="seller_rg"
            label="Identidade"
            rules={{ required: true }}
          />
          <DatePicker
            name="seller_birth_date"
            className="col-2"
            label="Data de Nascimento"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={sellerBirthDate}
            rules={{ required: true }}
            customInput={
              <IMaskInput
                mask={Date}
                pattern={'d/m/Y'}
                format={date => {
                  return moment(date).format(dateInputFormat)
                }}
                parse={value => {
                  return moment(value, dateInputFormat).toDate()
                }}
                validate={(date: string) => {
                  if (date.length === 10) {
                    if (moment(date, dateInputFormat).isAfter(moment())) {
                      return false
                    }
                    const age = moment().diff(
                      moment(date, dateInputFormat),
                      'year'
                    )
                    if (age < 18) {
                      return false
                    }
                  }
                  return true
                }}
              />
            }
            onChange={date => {
              setSellerBirthDate(date)
            }}
            controlled
          />
          <Input
            className="col-2"
            name="seller_phone"
            label="Telefone"
            rules={{
              required: true,
              minLength: { message: 'Número Invalido', value: 15 }
            }}
            mask={{
              mask: INPUT_MASK.PHONE
            }}
            controlled
          />
        </div>
        <div className="row mb-default">
          <Input
            className="col-2"
            name="seller_cell_phone"
            label="Celular"
            rules={{
              required: true,
              minLength: { message: 'Número Invalido', value: 14 }
            }}
            mask={{
              mask: INPUT_MASK.CELL_PHONE
            }}
            controlled
          />
          <Input
            className="col-2"
            name="seller_email"
            label="E-mail"
            rules={{
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Email inválido'
              }
            }}
          />
        </div>
        <div className="row mb-default">
          <Title title="Dados Bancários" />
          <Select
            className="col-2"
            label="Tipo"
            name="seller_type_account"
            rules={{ required: true }}
            options={OPTIONS_TYPE_ACCOUNT_BANK}
            defaultValue=""
            blank
          />
          <Input
            className="col-2"
            label="Cód. Banco"
            name="seller_bank_code"
            rules={{ required: true }}
          />
          <Input
            className="col-2"
            label="Agência"
            name="seller_agency"
            rules={{ required: true }}
          />
          <Input
            className="col-2"
            label="Conta"
            name="seller_account"
            rules={{ required: true }}
          />
          <Input
            className="col-2"
            label="Conta DV"
            name="seller_account_digit"
            rules={{ required: true }}
          />
        </div>

        <div className="row mb-default">
          <Title title="Comprador" className="mt-0" />

          <Input
            className="col-4"
            name="customer_name"
            label="Nome"
            rules={{ required: true }}
          />

          <Input
            className="col-2"
            name="customer_cpf"
            label="CPF"
            rules={{ required: true }}
            mask={{
              mask: '999.999.999-99',
              type: 'cpf'
            }}
            controlled
          />
          <Input
            className="col-2"
            name="customer_rg"
            label="Identidade"
            rules={{ required: true }}
          />
          <DatePicker
            name="customer_birth_date"
            className="col-2"
            label="Data de Nascimento"
            placeholderText="DD/MM/AAAA"
            dateFormat="dd/MM/yyyy"
            selected={customerBirthDate}
            rules={{ required: true }}
            customInput={
              <IMaskInput
                mask={Date}
                pattern={'d/m/Y'}
                format={date => {
                  return moment(date).format(dateInputFormat)
                }}
                parse={value => {
                  return moment(value, dateInputFormat).toDate()
                }}
                validate={(date: string) => {
                  if (date.length === 10) {
                    if (moment(date, dateInputFormat).isAfter(moment())) {
                      return false
                    }
                    const age = moment().diff(
                      moment(date, dateInputFormat),
                      'year'
                    )
                    if (age < 18) {
                      return false
                    }
                  }
                  return true
                }}
              />
            }
            onChange={date => {
              setCustomerBirthDate(date)
            }}
            controlled
          />
          <Input
            className="col-2"
            name="customer_phone"
            label="Telefone"
            rules={{ required: true }}
            mask={{
              mask: INPUT_MASK.PHONE
            }}
            controlled
          />
        </div>

        <div className="row mb-default">
          <Input
            className="col-2"
            name="customer_cell_phone"
            label="Celular"
            rules={{ required: true }}
            mask={{
              mask: INPUT_MASK.CELL_PHONE
            }}
            controlled
          />
          <Input
            className="col-2"
            name="customer_email"
            label="E-mail"
            rules={{
              required: true,
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: 'Email inválido'
              }
            }}
          />
          <Input
            className="col-2"
            name="customer_fgts_value"
            label="Valor FGTS"
            rules={{ required: true }}
            price
          />
          <Input
            className="col-2"
            name="customer_gross_value"
            label="Renda Bruta"
            rules={{ required: true }}
            price
          />
          <Input
            className="col-2"
            name="customer_entry_value"
            label="Valor Entrada"
            rules={{ required: true }}
            price
          />
          <Input
            className="col-2"
            name="customer_hiring_term"
            label="Prazo Contratação"
            rules={{ required: true }}
            mask={{
              mask: '999'
            }}
            controlled
          />
        </div>

        <div className="row mb-default">
          <Select
            className="col-2"
            label="Banco"
            name="customer_bank_id"
            rules={{ required: true }}
            options={banks}
            defaultValue=""
            blank
          />
        </div>

        <div className="row mb-default">
          <Title title="Imóvel" />

          <Select
            className="col-2"
            label="Tipo"
            name="immobile_type"
            rules={{ required: true }}
            options={OPTIONS_IMMOBILE_TYPES}
            defaultValue=""
            blank
          />
          <Select
            className="col-2"
            label="Situação"
            name="immobile_situation"
            rules={{ required: true }}
            options={OPTIONS_IMMOBILE_SITUATION}
            defaultValue=""
            blank
          />

          <Input
            className="col-4"
            name="immobile_registry"
            label="Cartório"
            rules={{ required: true }}
          />

          <Input
            className="col-2"
            name="immobile_registration"
            label="Matricula"
            rules={{ required: true }}
          />
          <Input
            className="col-2"
            name="immobile_value"
            label="Valor"
            rules={{ required: true }}
            price
          />
        </div>

        <div className="row mb-default">
          <Input
            className="col-2"
            label="CEP"
            name="immobile_zip_code"
            mask={{
              mask: '99999-999',
              type: 'zipCode',
              prefixInputsData: 'immobile_'
            }}
            controlled
            rules={{
              required: true,
              minLength: { value: 8, message: 'Cep Inválido' }
            }}
          />
          <Input
            className="col-4"
            label="Logradouro"
            name="immobile_street"
            rules={{ required: true }}
          />
          <Input
            className="col-2"
            label="Número"
            name="immobile_number"
            rules={{ required: true }}
          />
          <Input
            className="col-4"
            label="Complemento"
            name="immobile_complement"
          />
        </div>

        <div className="row mb-default">
          <Input
            className="col-4"
            label="Bairro"
            name="immobile_district"
            rules={{ required: true }}
          />
          <Input
            className="col-4"
            label="Cidade"
            name="immobile_city"
            rules={{ required: true }}
          />

          <Select
            className="col-2"
            label="Estado"
            name="immobile_state"
            rules={{ required: true }}
            options={states}
            defaultValue=""
            blank
          />
        </div>

        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="submit" className="btn btn-primary">
            Salvar
          </button>
        </div>
      </>
    </Form>
  )
}
