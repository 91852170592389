/* eslint-disable react/jsx-no-target-blank */
import {
  DrawerComponent,
  ScrollComponent,
  ToggleComponent
} from 'assets/ts/components'
import React, { FC, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'

import { AsideMenuMain } from '../AsideMenuMain'

interface Menu {
  id: number
  parent_id?: number
  method?: string
  name: string
  url?: string
  permission: boolean
  children?: Menu[]
  type: string
  icon: string
}
type Props = {
  subMenus: Menu
}
const TabsBase: FC<Props> = ({ subMenus }) => {
  return (
    <div className="d-flex h-100 flex-column">
      <div
        // id="kt_aside_menu_wrapper"
        // ref={scrollRef}
        // className="hover-scroll-overlay-y my-5 my-lg-5"
        // data-kt-scroll="true"
        // data-kt-scroll-activate="{default: false, lg: true}"
        // data-kt-scroll-height="auto"
        // data-kt-scroll-dependencies="#kt_aside_logo, #kt_aside_footer"
        // data-kt-scroll-wrappers="#kt_aside_menu"
        // data-kt-scroll-offset="0"
        className="flex-column-fluid hover-scroll-y"
        data-kt-scroll="true"
        data-kt-scroll-activate="true"
        data-kt-scroll-height="auto"
        data-kt-scroll-wrappers="#kt_aside_wordspace"
        data-kt-scroll-dependencies="#kt_aside_secondary_footer"
        data-kt-scroll-offset="0px"
      >
        <div className="tab-content">
          <div className="tab-pane fade active show" role="tabpanel">
            <div
              className="menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-bold fs-5 px-6 my-5 my-lg-0"
              id="kt_aside_menu"
              data-kt-menu="true"
            >
              <div id="kt_aside_menu_wrapper" className="menu-fit">
                <AsideMenuMain menuChildren={subMenus} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { TabsBase }
