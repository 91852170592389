import React from 'react'
import List from '../../pages/Config/Users/IpAllowed/List'
import Update from '../../pages/Config/Users/IpAllowed/Update'
import Create from '../../pages/Config/Users/IpAllowed/Create'
import View from '../../pages/Config/Users/IpAllowed/View'
import Route from '../Route'
import { CustomSwitch } from '../../components/CustomSwitch'

export const IpAllowedRouter = (): JSX.Element => (
  <CustomSwitch>
    <Route path="/users/ipAllowed" exact component={List} />
    <Route path="/users/ipAllowed/update/:id" component={Update} exact />
    <Route path="/users/ipAllowed/create" component={Create} exact />
    <Route path="/users/ipAllowed/view/:id" component={View} exact />
  </CustomSwitch>
)
