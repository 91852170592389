import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import PrivateRoutes from './Route'
import {
  SignIn,
  SignUp,
  Dashboard,
  Profile,
  Autenticate
} from './users/UsersRouter'
import { MenuRouter } from './config/MenuRouter'
import { RolesRouter } from './users/RolesRouter'
import { UsersRouter } from './config/UsersRouter'
import { PermissionsRouter } from './users/PermissionsRouter'
import { CacheRegisterRouter } from './cache/CacheRouter'
import { useAuth } from '../hooks/auth'
import { NotFoundPage } from '../pages/NotFound'
import { PermissionsAssociatedRouter } from './users/PermissionsAssociatedRouter'
import { IpAllowedRouter } from './users/IpAllowedRouter'
import { PartnersRouter } from './commercial/PartnersRoutes'
import { ProposalsRouter } from './commercial/ProposalsRoutes'
import { StatusProposalsRouter } from './commercial/StatusProposalsRoutes'
import { BanksRouter } from './commercial/BanksRoutes'

const Routes = () => {
  const { userLogged, backupHistory } = useAuth()
  return (
    <Switch>
      <Route path="/" exact component={SignIn}>
        {!backupHistory.length && userLogged() && <Redirect to="/dashboard" />}
      </Route>

      <Route path="/signup" component={SignUp} />
      <Route path="/autenticate/:data" component={Autenticate} />

      <PrivateRoutes path="/dashboard" component={Dashboard} />
      <PrivateRoutes path="/users/profile" component={Profile} />

      <Route path="/users/menus" component={MenuRouter} />

      <Route path="/users/roles" component={RolesRouter} />
      <Route path="/users/ipAllowed" component={IpAllowedRouter} />
      <Route path="/users/permissions" component={PermissionsRouter} />
      <Route
        path="/users/permissionsAssociated"
        component={PermissionsAssociatedRouter}
      />
      <Route path="/users" component={UsersRouter} />

      <Route path="/commercial/partners" component={PartnersRouter} />
      <Route path="/commercial/proposals" component={ProposalsRouter} />
      <Route
        path="/commercial/statusProposals"
        component={StatusProposalsRouter}
      />
      <Route path="/commercial/banks" component={BanksRouter} />

      <Route path="/cache" component={CacheRegisterRouter} />

      <Route path="*" component={NotFoundPage} />
    </Switch>
  )
}
export default Routes
