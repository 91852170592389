import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/Banks/List'
import Create from '../../pages/Commercial/Banks/Create'
import View from '../../pages/Commercial/Banks/View'
import Update from '../../pages/Commercial/Banks/Update'
import { CustomSwitch } from '../../components/CustomSwitch'

export const BanksRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/banks" exact component={List} />
    <PrivateRoutes path="/commercial/banks/create" exact component={Create} />
    <PrivateRoutes path="/commercial/banks/view/:id" exact component={View} />
    <PrivateRoutes
      path="/commercial/banks/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
