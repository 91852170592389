import React from 'react'
import PrivateRoutes from '../Route'
import List from '../../pages/Commercial/Partners/List'
import Create from '../../pages/Commercial/Partners/Create'
import View from '../../pages/Commercial/Partners/View'
import Update from '../../pages/Commercial/Partners/Update'
import { CustomSwitch } from '../../components/CustomSwitch'

export const PartnersRouter = (): JSX.Element => (
  <CustomSwitch>
    <PrivateRoutes path="/commercial/partners" exact component={List} />
    <PrivateRoutes
      path="/commercial/partners/create"
      exact
      component={Create}
    />
    <PrivateRoutes
      path="/commercial/partners/view/:id"
      exact
      component={View}
    />
    <PrivateRoutes
      path="/commercial/partners/update/:id"
      exact
      component={Update}
    />
  </CustomSwitch>
)
